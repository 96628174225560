import React, { createContext, useReducer, useContext } from 'react';

import { DisclaimerDataProps } from '../components/GeneralDisclaimer';
import { ScreenerDataProps } from '../components/PrePurchaseScreener';

export enum PrePurchaseModalActionsTypes {
  SetGeneralDisclaimer = 'SET_PRE_PURCHASE_DISCLAIMER',
  SetPrePurchaseScreener = 'SET_PRE_PURCHASE_SCREENER',
}

type PrePurchaseModalStateType = {
  elegibleCallback?: () => void;
  isGeneralDisclaimerOpen?: boolean;
  isPrePurchaseScreenerOpen?: boolean;
  generalDisclaimerData: DisclaimerDataProps | null;
  prePurchaseScreenerData: ScreenerDataProps | null;
};

type PrePurchaseModalActions = {
  payload: Partial<PrePurchaseModalStateType>;
  type: PrePurchaseModalActionsTypes;
};

export type PrePurchaseModalContextType = {
  state: PrePurchaseModalStateType;
  dispatch: (action: PrePurchaseModalActions) => void;
};

export const prePurchaseModalInitialState: PrePurchaseModalStateType = {
  isGeneralDisclaimerOpen: false,
  isPrePurchaseScreenerOpen: false,
  generalDisclaimerData: null,
  prePurchaseScreenerData: null,
};

const contextInitialState = {
  state: prePurchaseModalInitialState,
  dispatch: (action: PrePurchaseModalActions) => null,
};

export const PrePurchaseModalContext =
  createContext<PrePurchaseModalContextType>(contextInitialState);

const reducer = (
  state: PrePurchaseModalStateType,
  action: PrePurchaseModalActions,
): PrePurchaseModalStateType => {
  switch (action.type) {
    case PrePurchaseModalActionsTypes.SetPrePurchaseScreener:
      return {
        ...state,
        isPrePurchaseScreenerOpen: action.payload.isPrePurchaseScreenerOpen,
        prePurchaseScreenerData: action.payload.prePurchaseScreenerData!,
        generalDisclaimerData:
          action.payload.generalDisclaimerData || state.generalDisclaimerData,
        elegibleCallback: action.payload.elegibleCallback,
      };

    case PrePurchaseModalActionsTypes.SetGeneralDisclaimer:
      return {
        ...state,
        isGeneralDisclaimerOpen: action.payload.isGeneralDisclaimerOpen,
        generalDisclaimerData: action.payload.generalDisclaimerData!,
      };

    default:
      return state;
  }
};

export const PrePurchaseModalContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, prePurchaseModalInitialState);

  return (
    <PrePurchaseModalContext.Provider value={{ state, dispatch }}>
      {children}
    </PrePurchaseModalContext.Provider>
  );
};

// Pre Purchase Modal Context

export const usePrePurchaseModalContext = (): PrePurchaseModalContextType => {
  const contextValue = useContext(PrePurchaseModalContext);

  if (contextValue === undefined) {
    throw new Error(
      'Expected a PrePurchaseModalContext in the React tree to set the context value',
    );
  }

  return contextValue;
};
