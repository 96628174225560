import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  select {
    ${typography.bodyTextSmall};
    color: ${colors.gray3};
    padding: ${Number(size.xs1) + 2}px; /* Adjusting due to override */
  }

  .has-value select {
    color: ${colors.gray4};
  }
`;
