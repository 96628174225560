import { Container } from '@everlywell/leaves';
import { StructuredData } from 'components/StructuredData';
import { structuredProductList } from 'components/StructuredData/structuredDataHelpers';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {
  useQueryParam,
  NumberParam,
  BooleanParam,
  StringParam,
} from 'use-query-params';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { Flags } from 'utils/constants/featureFlags';
import { TITLES, DESCRIPTIONS } from 'utils/constants/meta';
import { COVID_SLUGS } from 'utils/constants/products';
import { APP_ROOT } from 'utils/constants/urls';
import { filterUniqueByCategory } from 'utils/helpers';
import { getActivePromo } from 'utils/helpers/activePromoHelpers';
import useProductCategories from 'utils/hooks/useProductCategories';
import usePromos from 'utils/hooks/usePromos';
import { ProductInfoMap, ProductDataType } from 'utils/types';

import Layout from '../components/Layout';
import PageControlSection from '../components/PIP/PageControlSection';
import PageHeading from '../components/PIP/PageHeading';
import {
  getProductsSortedBy,
  PIP_ORDER_LIST,
} from '../components/PIP/pip-order';
import ProductDisplayGrid from '../components/ProductDisplayGrid';
import { PromoProps } from '../components/ProductDisplayGrid/PromoCard';
import { SEO } from '../components/SEO';
import { PrePurchaseModalContextProvider } from '../contexts/PrePurchaseModal';

type Props = {
  // TODO: CG-1474
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageContext: any;
};

const VITAMINS_SUPPLEMENTS_PIP_ID = 11;
const NUTRIONAL_HEALTH_PIP_ID = 13;

const FDADisclaimerByCategory = {
  [VITAMINS_SUPPLEMENTS_PIP_ID]: true,
  [NUTRIONAL_HEALTH_PIP_ID]: true,
};

type FDADisclaimerCategory = keyof typeof FDADisclaimerByCategory;

function useFDADisclaimer(pipId: FDADisclaimerCategory | null) {
  if (!pipId) {
    return true;
  }

  return FDADisclaimerByCategory[pipId] || false;
}

const DEFAULT_SORT_BY = PIP_ORDER_LIST.BestSellers.key;

export const ProductInfoContext = createContext<ProductInfoMap>({});

const PIPTemplate = (props: Props): JSX.Element => {
  const { pageContext } = props;

  const { allTests, advertisedPromos } = pageContext;

  const { productCategoryMap } = useProductCategories();

  const { activePromos } = usePromos();

  const [activeCategory, setActiveCategory] = useQueryParam(
    'category',
    NumberParam,
  );

  const showFDADisclaimer = useFDADisclaimer(
    (activeCategory as FDADisclaimerCategory) || null,
  );

  const flags = useFlags();
  const flagToShowCovidCategory = flags[Flags.CovidPIPCategory];
  const [disableC19Param] = useQueryParam('disableC19', BooleanParam);

  const pcmKeys = Object.keys(productCategoryMap);

  const [productCategoryKeys, setProductCategoryKeys] =
    useState<Array<string>>(pcmKeys);

  const activePromo = useMemo(
    () => getActivePromo(advertisedPromos),
    [advertisedPromos],
  );

  // Array of all products - contains duplicate entries for products shared between categories
  const showableTests = useMemo(
    () =>
      allTests.filter((p: ProductDataType) => {
        if (disableC19Param) {
          return !COVID_SLUGS.includes(p.slug);
        } else {
          return true;
        }
      }),
    [allTests, disableC19Param],
  );

  // Array of unique test objects
  const uniqueTests = useMemo<ProductDataType[]>(
    () =>
      showableTests.reduce((unique: ProductDataType[], o: ProductDataType) => {
        if (!unique.some((obj) => obj.productId === o.productId)) {
          unique.push(o);
        }
        return unique;
      }, []),
    [showableTests],
  );

  // Remove Covid from Categories if URL Param present
  useEffect(() => {
    if (flagToShowCovidCategory === false || disableC19Param) {
      setProductCategoryKeys(pcmKeys.filter((cat) => cat !== 'COVID'));
    } else {
      setProductCategoryKeys(pcmKeys);
    }
    // This needs to listen to productCategoryMap and pcmKeys too, but it's causing a memory leak
  }, [disableC19Param, flagToShowCovidCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const allCategories = useMemo(
    () => [
      'All Products',
      ...productCategoryKeys.sort(
        (a: string, b: string) =>
          productCategoryMap[a].order - productCategoryMap[b].order,
      ),
    ],
    [productCategoryKeys, productCategoryMap],
  );

  const getValidatedSortByParam = (sortByValue: string | null | undefined) =>
    sortByValue && PIP_ORDER_LIST[sortByValue]
      ? PIP_ORDER_LIST[sortByValue].key
      : DEFAULT_SORT_BY;

  const activeCategoryIdx = useRef<number>(0);
  const selectedCategory = useRef<string>('');
  const visiblePromos = useRef<PromoProps[]>([]);
  const categoryPromos = useRef<Record<string, Record<string, unknown>>>({
    'All Products': {},
  });
  // TODO: Revisit this after CG-1474
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categoryPromo, setCategoryPromo] = useState<any>();
  const [filterResults, setFilterResults] = useState<ProductDataType[]>([]);

  const [sortByQueryParam, setSortByQueryParam] = useQueryParam(
    'sortBy',
    StringParam,
  );
  const [sortBy, setSortBy] = useState(
    getValidatedSortByParam(sortByQueryParam),
  );

  const structuredProductData = structuredProductList(
    filterResults.map((product) => product.slug),
  );

  const trackCategoryFilter = (category: string, source: string): void => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: `${ANALYTICS.LABELS.PIP_CATEGORY_FILTER} - ${source}`,
        category,
      },
    });
  };

  const handleCategorySelect = (i: number, source: string) => {
    const selectedProductCategory = productCategoryKeys[i - 1];
    const selectedProductCategoryData =
      productCategoryMap[selectedProductCategory];

    setActiveCategory(selectedProductCategoryData?.pipId, 'replaceIn');

    const category = allCategories[i];

    if (i > 0) {
      /**
       * Products can belong to multiple categories - we must remove duplicates from the filtered list. This is happening because we need to support category & categories currently.
       */
      const filteredTests = filterUniqueByCategory(showableTests, category);
      setFilterResults(filteredTests);
    } else {
      setFilterResults(uniqueTests);
    }

    activeCategoryIdx.current = i;
    trackCategoryFilter(category, source);
  };

  const directCategoryLink = useCallback(
    (index: number) => {
      /**
       * This method is necessary because of the discrepancy between
       * the category number and the category order/index.
       *
       * also, it's not best practice to set variables to undefined,
       * but this is how it was described in the docs here:
       * https://github.com/pbeshai/use-query-params#usequeryparam
       **/

      let filteredTests = uniqueTests;

      if (index > 0) {
        const category = productCategoryKeys.find(
          (cat) => productCategoryMap[cat].pipId === index,
        );
        if (!category) return;
        /**
         * Products can belong to multiple categories - we must remove duplicates from the filtered list. This is happening because we need to support category & categories currently.
         */
        filteredTests = filterUniqueByCategory(showableTests, category);
        activeCategoryIdx.current = allCategories.indexOf(category);
      } else {
        activeCategoryIdx.current = 0;
      }

      const sortedTests = getProductsSortedBy(filteredTests, sortBy);
      setFilterResults(sortedTests);
    },
    // Removing productCategoryMap because useProductCategories causes a memory leak.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCategories, productCategoryKeys, showableTests, uniqueTests, sortBy],
  );

  const trackPageView = (): void => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: 'PIP',
      },
    });
  };

  useEffect(() => {
    categoryPromos.current = pcmKeys.reduce(
      (acc: Record<string, Record<string, unknown>>, category) => {
        const { promoActive, promoRow, promoColumn, promoSpan, promo } =
          productCategoryMap[category];
        acc[category] = {
          active: promoActive,
          row: promoRow,
          column: promoColumn,
          span: promoSpan,
          ...promo,
        };
        return acc;
      },
      {},
    );

    if (activeCategory) {
      /**
       * Per https://everlyhealth.atlassian.net/browse/FE-520?focusedCommentId=188872
       * we need to set a hard redirect from category 4 to category 13.
       *
       * TODO: remove the need for this hard redirect by disabling category 4 in
       * all marketing campaigns
       */
      const activeCat = activeCategory === 4 ? 13 : activeCategory; // redirect to 13 if activeCategory is 4
      directCategoryLink(Number(activeCat));
    } else {
      setFilterResults(uniqueTests);
    }

    trackPageView();
    // TODO: handle memory leaks caused by adding 'activeCategory', 'directCategoryLink', 'pcmKeys', 'productCategoryMap', and 'uniqueTests' to dep list
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterResults.length > 0) {
      selectedCategory.current = allCategories[activeCategoryIdx.current];
      setCategoryPromo(categoryPromos.current[selectedCategory.current]);
    }
  }, [filterResults, allCategories]);

  useEffect(() => {
    if (activeCategoryIdx.current === 0) {
      visiblePromos.current = activePromos;
    } else if (categoryPromo) {
      visiblePromos.current = [categoryPromo];
    }
  }, [categoryPromo, activePromos]);

  useEffect(() => {
    directCategoryLink(activeCategory || 0);
  }, [activeCategory, directCategoryLink]);

  const handleOnSortByChange = (value: string) => {
    setSortBy(value);
    setSortByQueryParam(value, 'replaceIn');

    const category = allCategories[activeCategoryIdx.current];
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.DROPDOWN_SELECTION,
      data: {
        label: `PIP Sort By`,
        selection: PIP_ORDER_LIST[value].label,
        category,
      },
    });
  };

  return (
    <div data-testid="pip-page">
      <SEO
        title={TITLES.PRODUCTS}
        description={DESCRIPTIONS.PRODUCTS}
        url={`${APP_ROOT}/products/`}
        canonicalUrl={
          activeCategory ? `products/?category=${activeCategory}` : `products`
        }
      />
      <StructuredData data={structuredProductData} />
      <Layout showFDADisclaimer={showFDADisclaimer}>
        <PageHeading />
        <PageControlSection
          handleCategorySelect={handleCategorySelect}
          allTabItems={allCategories}
          categoryIndex={activeCategoryIdx.current}
          numberOfResults={filterResults.length}
          onSortByChange={handleOnSortByChange}
          preSelectedSortBy={getValidatedSortByParam(sortByQueryParam)}
        />
        <PrePurchaseModalContextProvider>
          <Container>
            <ProductDisplayGrid
              products={filterResults}
              visiblePromos={visiblePromos.current}
              activePromo={activePromo}
              columnConfig={{
                tabletVerticalUpColumns: 2,
                tabletHorizontalUpColums: 3,
              }}
              cardTrackingLabel={ANALYTICS.LABELS.PIP_PRODUCT_CARD}
              addToCartTrackingLabel={ANALYTICS.LABELS.PIP_ADD_TO_CART_BUTTON}
            />
          </Container>
        </PrePurchaseModalContextProvider>
      </Layout>
    </div>
  );
};

export default PIPTemplate;
