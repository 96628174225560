import { H1, Container, Col, Row } from '@everlywell/leaves';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

import ornaments from '../../../images/themes/Winter2020/ornaments.svg';
import { Theme, Themable } from '../../Themable';
import lines from './4lines.svg';
import * as S from './styles';

type PageHeadingProps = {
  headline?: string;
  subHeadline?: string;
};

export const PurePageHeading: React.FC<PageHeadingProps> = ({
  headline,
  subHeadline,
}) => (
  <Container>
    <Row>
      <Col xs={12}>
        <Themable>
          <S.Img
            // @ts-ignore - the theme prop is only used for Themeable to find and filter
            theme={Theme.Default}
            src={lines}
            aria-hidden="true"
            alt="decorative lines"
            width={90}
            height={72}
          />
          <S.Img
            // @ts-ignore - the theme prop is only used for Themeable to find and filter
            theme={Theme.Winter2020}
            src={ornaments}
            aria-hidden="true"
            alt="decorative ornaments"
          />
        </Themable>
      </Col>
    </Row>
    <Row center="xs">
      <Col xs={12}>
        {headline ? <H1>{headline}</H1> : null}
        {subHeadline ? <S.Disclaimer>{subHeadline}</S.Disclaimer> : null}
      </Col>
    </Row>
  </Container>
);

export const PageHeading = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulPageSection(sectionId: { eq: "PIP - Page Heading" }) {
          headline
          subHeadline
        }
      }
    `}
    render={(contentfulData) => {
      const { contentfulPageSection: data } = contentfulData;
      return (
        <PurePageHeading
          headline={data.headline}
          subHeadline={data.subHeadline}
        />
      );
    }}
  />
);

export default PageHeading;
