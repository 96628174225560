import { size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Img = styled.img`
  width: ${size.xl4}px;
`;

export const Disclaimer = styled.p`
  ${typography.captionText};
  margin-top: ${size.md}px;
`;
