import { graphql, useStaticQuery } from 'gatsby';

const useProductCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCategory {
        nodes {
          title
          order
          pipId
          promoActive
          promoRow
          promoColumn
          promoSpan
          promo {
            slug
            content
            link
            image {
              description
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          product {
            slug
          }
        }
      }
      allReviews {
        nodes {
          product_name
          product_page_id
          average_rating
          native_review_count
          question_count
          rating_histogram
          recommended_ratio
          review_count
          syndicated_review_count
        }
      }
    }
  `);

  // We use this reducer to maintain the API of the old way we did this.
  // If we can just... not do this, that'd be great.
  const productCategoryMap = data.allContentfulCategory.nodes.reduce(
    productMapReducer,
    {},
  );

  return { productCategoryMap };
};

const productMapReducer = (acc: any, next: any) => {
  acc[next.title] = next;
  return acc;
};

export default useProductCategories;
