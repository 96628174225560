import { Dropdown } from '@everlywell/leaves';
import React, { ChangeEvent, useState } from 'react';

import * as S from './SortBy.styles';

export type SortByOption = {
  id: string;
  value: string;
  text: string;
  isDisabled?: boolean;
};

export type SortByProps = {
  id: string;
  name: string;
  options: SortByOption[];
  label?: string;
  placeholderText?: string;
  preSelectedValue?: string;
  onChange?: (value: string) => void;
};

/**
 * Sort By component
 */
export default function SortBy({
  id,
  name,
  options,
  placeholderText,
  label,
  preSelectedValue,
  onChange,
}: SortByProps): JSX.Element {
  const [hasChanged, setHasChanged] = useState(false);

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setHasChanged(true);
    if (onChange) {
      onChange(event?.target?.value);
    }
  };

  return (
    <S.Container>
      <Dropdown
        id={id}
        name={name}
        items={options}
        label={label ? label : ''}
        placeholderText={placeholderText ? placeholderText : ''}
        onChange={handleOnChange}
        className={hasChanged || preSelectedValue ? 'has-value' : ''}
        value={preSelectedValue}
      />
    </S.Container>
  );
}
