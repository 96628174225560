import { Tab } from '@everlywell/leaves';
import { TabMenu } from '@everlywell/leaves';
import { Col, useHooks, breakpoints } from '@everlywell/leaves';
import React, { useState, useEffect } from 'react';

import { PIP_ORDER_LIST } from '../pip-order';
import SortBy from '../SortBy';
import * as S from './styles';

export type PageControlSectionProps = {
  handleCategorySelect: (categoryIndex: number, source: string) => void;
  allTabItems: string[];
  categoryIndex: number;
  numberOfResults: number;
  onSortByChange: (value: string) => void;
  preSelectedSortBy?: string;
};

export const PageControlSection = ({
  handleCategorySelect,
  allTabItems,
  categoryIndex,
  numberOfResults,
  onSortByChange,
  preSelectedSortBy,
}: PageControlSectionProps): JSX.Element => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const windowSize = useHooks.useWindowSize();
  const isTabletHorizontalUp = windowSize?.width
    ? windowSize.width >= breakpoints.forTabletHorizontalUp
    : false;

  const isTabletVerticalUp = windowSize?.width
    ? windowSize.width >= breakpoints.forTabletVerticalUp
    : false;

  const sortByOptions = Object.values(PIP_ORDER_LIST).map((item) => ({
    id: item.key,
    value: item.key,
    text: item.label,
  }));

  const handleSortByChange = (value: string) => {
    onSortByChange(value);
  };

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  return (
    <S.Container>
      <S.PageControlSectionRow center="xs" middle="sm">
        <S.TabMenuWrapper xs={12}>
          <TabMenu
            onItemSelect={(index: number) =>
              handleCategorySelect(
                index,
                isTabletHorizontalUp ? 'Desktop' : 'Mobile',
              )
            }
            initialActiveIndex={categoryIndex}
            ariaLabel="Product Category Filter"
            ariaControls="productGrid"
            id="tab-product"
            isScrollable={!isTabletHorizontalUp}
            hasArrows={!isTabletHorizontalUp}
            addContainerPadding={!isTabletVerticalUp}
          >
            {allTabItems.map((item, i) => (
              <Tab key={i}>{item}</Tab>
            ))}
          </TabMenu>
        </S.TabMenuWrapper>

        {/* Show selected category only for horizontal tablets and up */}
        {isTabletHorizontalUp ? (
          <Col xs={12} sm={6}>
            <S.DisplayTitle data-testid="selected-category">
              {allTabItems[categoryIndex]}
            </S.DisplayTitle>
          </Col>
        ) : null}

        <S.ResultsSortByWrapper xs={12} md={6}>
          <S.NumberOfResults>{numberOfResults} results</S.NumberOfResults>
          {hasLoaded ? (
            <S.SortByWrapper>
              <SortBy
                id="sort-by"
                name="sort-by"
                options={sortByOptions}
                onChange={handleSortByChange}
                placeholderText="Sort By"
                preSelectedValue={preSelectedSortBy}
              />
            </S.SortByWrapper>
          ) : null}
        </S.ResultsSortByWrapper>
      </S.PageControlSectionRow>
    </S.Container>
  );
};

export default PageControlSection;
