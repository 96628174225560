import {
  size,
  typography,
  mediaQueries,
  Col as RawCol,
  Row as RawRow,
  Container as LeavesContainer,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const PageControlSectionRow = styled(RawRow)`
  min-height: ${size.xl5}px;

  /* Non-mobile first approach to override leaves */
  ${mediaQueries.forPhoneOnly} {
    padding: 0 !important;
    margin: 0 !important;
  }

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: ${size.xl2}px;
    margin-bottom: ${size.lg}px;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl2}px;
    min-height: ${size.xl7}px;
  }
`;

export const DisplayTitle = styled.h2`
  ${typography.displayText}
  margin-bottom: ${size.lg}px;
  margin-top: ${size.lg}px;
  width: 100%;
  font-size: 22.8px;
  ${mediaQueries.forTabletVerticalUp} {
    font-size: ${size.xl1}px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
  }
`;

export const NumberOfResults = styled.p`
  ${typography.bodyText}
  margin: ${size.sm}px ${size.md}px ${size.lg}px 0;
  text-align: left;
  margin-left: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    margin-bottom: 0;
    margin-left: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-right: 0;
    text-align: right;
  }
`;

export const TabMenuWrapper = styled(RawCol)`
  margin-top: ${size.lg}px;

  /* Non-mobile first approach to override leaves */
  ${mediaQueries.forPhoneOnly} {
    padding: 0 !important;
  }

  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 0;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl4}px;
  }
`;

export const Container = styled(LeavesContainer)`
  /* Non-mobile first approach to override leaves */
  ${mediaQueries.forPhoneOnly} {
    padding: 0 !important;
  }
`;

export const ResultsSortByWrapper = styled(RawCol)`
  display: flex;
  justify-content: space-between;
  margin-right: ${size.lg}px;
  margin-top: ${size.sm}px;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-end;
    gap: ${size.sm}px;
    margin: 0;
  }
`;

export const SortByWrapper = styled.div`
  width: 180px;
`;
