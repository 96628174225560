import { ProductDataType } from '../../utils/types';

// Order provided by the product team
export const pipRelevanceOrder: string[] = [
  'food-allergy-test',
  'multivitamin-gummy',
  'vitamin-d3-2000-iu',
  'omega-3-fish-oil-1000-mg',
  'food-sensitivity',
  'food-sensitivity-comprehensive-test',
  'celiac-disease-screening-test',
  'vitamin-b12-500-mcg',
  'vitamin-b6-50-mg',
  'metabolism',
  'womens-health-test',
  'mens-health-test',
  'std-test-female',
  'std-test-male',
  'testosterone-test',
  'thyroid-test',
  'chlamydia-gonorrhea-test',
  'indoor-outdoor-allergy-test',
  'vitamin-d-and-inflammation-test',
  'vitamin-d-test',
  'cholesterol-and-lipids-test',
  'hba1c',
  'hpv-test-female',
  'heart-health-test',
  'fit-colon-cancer-screening-test',
  'trichomoniasis-test',
  'hiv-test',
  'sleep-and-stress-panel',
  'perimenopause-test',
  'womens-fertility-test',
  'elements-panel',
  'ovarian-reserve-test',
  'lyme-disease-test',
  'syphilis-test',
  'postmenopause-test',
  'hepatitis-c-test',
  'b-vitamins-test',
];

export const pipBestSellerOrder: string[] = [
  'food-sensitivity',
  'food-sensitivity-comprehensive-test',
  'womens-health-test',
  'mens-health-test',
  'metabolism',
  'std-test-female',
  'std-test-male',
  'thyroid-test',
  'food-allergy-test',
  'testosterone-test',
  'chlamydia-gonorrhea-test',
  'celiac-disease-screening-test',
  'cholesterol-and-lipids-test',
  'hba1c',
  'fit-colon-cancer-screening-test',
  'vitamin-d-and-inflammation-test',
  'indoor-outdoor-allergy-test',
  'hpv-test-female',
  'heart-health-test',
  'lyme-disease-test',
  'vitamin-d-test',
  'sleep-and-stress-panel',
  'trichomoniasis-test',
  'hiv-test',
  'perimenopause-test',
  'womens-fertility-test',
  'elements-panel',
  'postmenopause-test',
  'ovarian-reserve-test',
  'syphilis-test',
  'vitamin-b12-500-mcg',
  'hepatitis-c-test',
  'vitamin-d3-2000-iu',
  'omega-3-fish-oil-1000-mg',
  'vitamin-b6-50-mg',
  'multivitamin-gummy',
  'b-vitamins-test',
];

export const pipNewestFirstOrder: string[] = [
  'multivitamin-gummy',
  'food-allergy-test',
  'celiac-disease-screening-test',
  'vitamin-b12-500-mcg',
  'vitamin-d3-2000-iu',
  'omega-3-fish-oil-1000-mg',
  'vitamin-b6-50-mg',
  'food-sensitivity',
  'food-sensitivity-comprehensive-test',
  'womens-health-test',
  'mens-health-test',
  'metabolism',
  'std-test-female',
  'std-test-male',
  'thyroid-test',
  'testosterone-test',
  'chlamydia-gonorrhea-test',
  'cholesterol-and-lipids-test',
  'hba1c',
  'fit-colon-cancer-screening-test',
  'vitamin-d-and-inflammation-test',
  'indoor-outdoor-allergy-test',
  'hpv-test-female',
  'heart-health-test',
  'lyme-disease-test',
  'vitamin-d-test',
  'sleep-and-stress-panel',
  'trichomoniasis-test',
  'hiv-test',
  'perimenopause-test',
  'womens-fertility-test',
  'elements-panel',
  'postmenopause-test',
  'ovarian-reserve-test',
  'syphilis-test',
  'hepatitis-c-test',
  'b-vitamins-test',
];

export type PIP_ORDER_CRITERIA = {
  name: string;
  key: string;
  type: 'ASC' | 'DESC';
  label: string;
};

export const PIP_ORDER_LIST: Record<string, PIP_ORDER_CRITERIA> = {
  BestSellers: {
    name: 'bestSellerOrder',
    key: 'BestSellers',
    type: 'ASC',
    label: 'Best Sellers',
  },
  Relevance: {
    name: 'relevanceOrder',
    key: 'Relevance',
    type: 'ASC',
    label: 'Relevance',
  },
  Newest: {
    name: 'newestFirstOrder',
    key: 'Newest',
    type: 'ASC',
    label: 'Newest',
  },
  AZ: {
    name: 'displayName',
    key: 'AZ',
    type: 'ASC',
    label: 'A-Z',
  },
  ZA: {
    name: 'displayName',
    key: 'ZA',
    type: 'DESC',
    label: 'Z-A',
  },
  PriceLowHigh: {
    name: 'price',
    key: 'PriceLowHigh',
    type: 'ASC',
    label: 'Price (Low-High)',
  },
  PriceHighLow: {
    name: 'price',
    key: 'PriceHighLow',
    type: 'DESC',
    label: 'Price (High-Low)',
  },
};

export const getProductsSortedBy = (
  products: ProductDataType[],
  sortBy: string,
): ProductDataType[] =>
  products.sort((currentProduct, nextProduct) => {
    // Exclude out of stock items from the comparison
    if (currentProduct.outOfStock || nextProduct.outOfStock) {
      return 0;
    }

    const currentProp = getNormalizedProductSortingProp(currentProduct, sortBy);
    const nextProp = getNormalizedProductSortingProp(nextProduct, sortBy);

    // If the order is reverse (DESC), multipy by -1
    const upOrDown = PIP_ORDER_LIST[sortBy].type === 'ASC' ? 1 : -1;

    // Exclude undefined items from the comparison
    if (currentProp === undefined || nextProp === undefined) {
      return 0;
    }

    if (currentProp < nextProp) {
      return -1 * upOrDown;
    }

    if (currentProp > nextProp) {
      return 1 * upOrDown;
    }

    return 0;
  });

const getNormalizedProductSortingProp = (
  product: ProductDataType,
  rawSortingPropNane: string,
) => {
  const sortingPropName = PIP_ORDER_LIST[rawSortingPropNane].name;
  const rowProductPropValue = product[sortingPropName as keyof ProductDataType];

  const transformedPropValue =
    typeof rowProductPropValue === 'string'
      ? rowProductPropValue.toString().toLocaleLowerCase()
      : rowProductPropValue;

  return transformedPropValue;
};
