import { graphql, useStaticQuery } from 'gatsby';

const usePromos = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPromo {
        nodes {
          slug
          active
          content
          link
          row
          column
          span
          image {
            description
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
      }
    }
  `);

  const allPromos = data.allContentfulPromo.nodes;
  const activePromos = allPromos.filter((promo: any) => promo.active);

  return { allPromos, activePromos };
};

export default usePromos;
